import React, { useState, useEffect } from 'react'
import { navigate } from "gatsby"
import Header from "../components/header"
import HeaderMenu from "../components/header-menu"
import Footer from "../components/footer"
import Alert from "../components/alert"
import { CSSTransition } from 'react-transition-group';
import { getUser} from "../services/auth"
import {useEmpreses, especific} from "../helpers/useEmpreses"
import TransitionData from "../components/transitionData"
import TransitionTransport from "../components/transitionTransport"
import TransitionDestiDestete from "../components/transitionDestiDestete"
import TransitionResult from "../components/transitionResult"
import TransitionDestiEngreix from "../components/transitionDestiEngreix"

import axios from 'axios';

export default () => {
  var empreses=useEmpreses()
  //Step controls
  const [stepActive, setStepActive] = useState(1);
  const [stepPrev, setStepPrev] = useState(0);
  //origenData
  const [granjesMares, setGranjesMares] = useState([]);
  const [granjesDestete, setGranjesDestete] = useState([]);
  const [granjesEngreix, setGranjesEngreix] = useState([]);
  const [transports, setTransports] = useState([]);
  //result Data
  const [allResults, setAllResults ] =  useState([]);
  const [finalResults, setFinalResults ] =  useState([]);
  const [finalValues, setFinalValues ] =  useState({});
  const [showAlertStep, setShowAlertStep] = useState(false);
  const [messageAlertStep, setMessageAlertStep] = useState("");
  const [isLoading, setLoading] = useState(false)

  const resetStepActive = event => {
    event.preventDefault();
    setStepActive(1);
  }
   
  const closeAlertStep = event => {
    setShowAlertStep(false)
  }
  const setResults = (resString,resFinal)=> {
    let pos=stepPrev-1;
    let ar=allResults;
    if (!ar[pos]){
      if (pos<=0) {
        ar.push(resString)
      } else ar.splice(pos, 1, resString);
    } else  ar.splice(pos, 1, resString);
    setAllResults(ar)
    buildResults();
  }

 const buildResults=()=>{
    let _finalResults=new Array();
    for (var i=0;i<allResults.length;i++){
        for (var j=0;allResults[i].length;j++){
          _finalResults.push(allResults[i][j])
          if (j+1>=allResults[i].length) {
            setFinalResults(_finalResults)
            break
          }
        }
    }
  }
  
  const handleStepUp = (value) => {
   if (value!==5) {
      setStepPrev(value);
      setStepActive(value + 1);
   }
  }

  const handleStepDown = (value) => {
     if (value!==1) {
      setStepPrev(value);
      setStepActive(value - 1);
     }
  }

  const getEmpresa =(idEmpresa) =>{
    var empresa="";
    for (var _empresa in empreses.allEmpresa.nodes){
        console.log("busquem empresa "+idEmpresa+", "+empreses.allEmpresa.nodes[_empresa].idEmpresa)
        if (empreses.allEmpresa.nodes[_empresa].idEmpresa===idEmpresa) {
          console.log("empresa trobada "+JSON.stringify(empreses.allEmpresa.nodes[_empresa]))
          empresa=empreses.allEmpresa.nodes[_empresa]
          break;
        }
    }
    return empresa;
  }

  useEffect( () => {
    var empresa=""
    if (getUser()) empresa=getEmpresa(getUser().idEmpresa);
    console.log("GRANJES ENGREIX========="+JSON.stringify(empresa.granjesEngreix))
    setGranjesMares(especific(empresa.granjesMares,getUser()))
    setGranjesDestete(especific(empresa.granjesDestete,getUser()))
    console.log("______________________________**")
    let granjesEngreix=especific(empresa.granjesEngreix,getUser())
    console.log("les granjes d'engreix son "+JSON.stringify(granjesEngreix))
    setGranjesEngreix(granjesEngreix)
    setTransports(empresa.transports)
  }, []);

  const handleConfirm= (e) =>{
      e.preventDefault();
      const formData = new FormData();
      formData.append('foto', finalValues.foto);
      console.log("formData ="+formData)
      console.log("foto finalvalues="+finalValues.foto)
      formData.append("MARCA_ORG", finalValues.destiDesteteSelected)
      formData.append("NAU_ORG",finalValues.nauSelected)
      formData.append("MARCA_DES", finalValues.granjaEngreixSelected)
      formData.append("NAU_DES",finalValues.nauEngreixSelected)
      formData.append("DATA",finalValues.data)
      formData.append("ANIMALS",finalValues.numanimals)
      formData.append("PESVIU",finalValues.pesviu)
      formData.append("GUIA", finalValues.guia ||"")
      formData.append("id_usuari",getUser().id)
      formData.append("id_transportista",finalValues.transportSelected)
      formData.append("id_xofer" ,finalValues.xoferSelected)
      formData.append("OBSERVACIONS" , finalValues.observacions || "")
      axios.defaults.headers.common['Authorization'] =  getUser().token;
      setLoading(true)
      axios({
        method: "post",
        url: "/auth/desteteEngreix",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" }
      }).then(res => {
        setLoading(false)
        if (res.data.error) {
          console.log("ERROR AL INSERTAR = "+JSON.stringify(res.data) )   
          setMessageAlertStep(res.data.error)
          setShowAlertStep(true)
        } else{
          navigate("/mainMenu")
        }
     }).catch(error => {
        setMessageAlertStep(error.message)
        setShowAlertStep(true)
        setLoading(false)
     })  
  }
     
  return (
      <React.Fragment>
        <div className="granjatec-wrapper">
          <Header/>
          <HeaderMenu />
          <div className="main-container">
              <section className="section heading-opt">
                <div className="heading-opt-title">
                  <h2 className="h5">Destete a engreix</h2>
                </div>
              </section>
              <section className="section form-opt destete-engreix">
                <form className="steps">
                    <TransitionDestiDestete index={1} 
                        stepActive={stepActive}
                        stepPrev={stepPrev}
                        origen={true}
                        arrayData={granjesDestete}
                        handleStepActiveUp={handleStepUp}
                        handleStepActiveDown={handleStepDown}
                        callbackStrings={setResults}
                        callbackValues={finalValues}/> 

                    <TransitionData index={2} 
                        stepActive={stepActive}
                        stepPrev={stepPrev}
                        arrayData={granjesMares} 
                        handleStepActiveUp={handleStepUp}
                        handleStepActiveDown={handleStepDown}
                        callbackStrings={setResults}
                        callbackValues={finalValues} />

                    <TransitionDestiEngreix index={3} 
                        stepActive={stepActive}
                        stepPrev={stepPrev}
                        arrayData={granjesEngreix}
                        handleStepActiveUp={handleStepUp}
                        handleStepActiveDown={handleStepDown}
                        callbackStrings={setResults}
                        callbackValues={finalValues} /> 

                    <TransitionTransport index={4} 
                        stepActive={stepActive}
                        stepPrev={stepPrev}
                        arrayData={ transports}
                        validation={false}
                        handleStepActiveUp={handleStepUp}
                        handleStepActiveDown={handleStepDown}
                        callbackStrings={setResults}
                        callbackValues={finalValues}/> 

                    <TransitionResult index={5} 
                        stepActive={stepActive}
                        stepPrev={stepPrev}
                        arrayData={finalResults}
                        resetStepActive={resetStepActive}
                        handleConfirm={handleConfirm} />

                </form>
              </section>
              { isLoading && (
              <div className="overlay-loading"></div>
              )}
            </div>
            <CSSTransition
              in={showAlertStep}
              timeout={300}
              classNames={'alertright'}
              unmountOnExit >
              <Alert type="error">
                <div className="alert-message">
                  {messageAlertStep}
                </div>
                <span className="alert-close" onClick={closeAlertStep}>
                  <i className="icon-close"></i>
                </span>
              </Alert>
            </CSSTransition>
          <Footer/>
        </div>
    </React.Fragment>

)}