import React, { useState } from 'react'
import { CSSTransition } from 'react-transition-group';
import classNames from "classnames"
import "react-datepicker/dist/react-datepicker.css";
import Alert from "./alert"
import Autocomplete from "../components/autocomplete"

const TransitionDestiEngreix = props => {
  
  const [isNauEngreixVisible, setNauEngreixVisible] = useState(false);
  const [nauEngreixStringSelected, setNauEngreixStringSelected] = useState("");
  const [nauEngreixSelected, setNauEngreixSelected] = useState("");
  const [nausEngreix, setNausEngreix] = useState([]);
  const [granjaEngreixSelected,setGranjaEngreixSelected] =useState("");
  const [granjaEngreixStringSelected,setGranjaEngreixStringSelected] =useState("");
  const [showAlertStep, setShowAlertStep] = useState(false);
  const [messageAlertStep, setMessageAlertStep] = useState("");
 
  const handleNauEngreixSelected= (e) =>{
    setNauEngreixSelected(e.target.value)
    setNauEngreixStringSelected(e.target.options[e.target.selectedIndex].label)
  }
  
  const handleGranjesEngreixSelected= (text,index) =>{
    let granja= text.split("-");
    let code= granja[0].trim();
    let granjaText=granja[1].trim()
    code = code.replace(/^\s+/g, '');
    granjaText=granjaText.replace(/^\s+/g, '');

    if (index<0 || index==undefined) {
      setGranjaEngreixSelected(code)
      setGranjaEngreixStringSelected("");
      setNauEngreixVisible(false)
      setNausEngreix([])
      return
    }
    setGranjaEngreixSelected(code)
    setGranjaEngreixStringSelected(code+"-"+granjaText)
    setNausEngreix(props.arrayData[index].naus)
    if (props.arrayData[index].naus.length>1) {
      setNauEngreixVisible(true)
    }else { 
      setNauEngreixVisible(false)
      setNauEngreixStringSelected(props.arrayData[index].naus[0].nomNau)
      setNauEngreixSelected(props.arrayData[index].naus[0].idNau)
   }
  }

  const closeAlertStep = event => {
    setShowAlertStep(false)
  }
  
  const onExit = () =>{
    var res=[];
    res.push({
      "key":props.origen? `Origen` :`Destí`,
      "value": granjaEngreixStringSelected
    })

    res.push({
      "key":"Nau",
      "value": nauEngreixStringSelected
    })
    props.callbackValues.granjaEngreixSelected=granjaEngreixSelected;
    props.callbackValues.nauEngreixSelected=nauEngreixSelected;
    props.callbackValues.isNauEngreixVisible=isNauEngreixVisible;
    props.callbackStrings(res)
  }

  const handleClickUp = (e) => {
    e.preventDefault();
    if (isNauEngreixVisible && !nauEngreixSelected){
      setMessageAlertStep("Has de seleccionar una nau")
      setShowAlertStep(true)
      return
    }
    if (!isNauEngreixVisible && !granjaEngreixSelected){
      setMessageAlertStep("Has de seleccionar un Destí Engreix ")
      setShowAlertStep(true)
      return
    }
    setShowAlertStep(false)
    props.handleStepActiveUp(props.stepActive)
  }

  const handleClickDown = (e) => {
    e.preventDefault();
    props.handleStepActiveDown(props.stepActive)
  }

  const objectGranjes = Object.entries(props.arrayData);
  var ArrayGranjesAutocomplete = [];
  objectGranjes.forEach(([key, value]) => {
    var item = {};
    if (value.marca){
     // console.log("value "+value.marca +"-"+value.nomMarca)
      item.value = value.marca;
      item.label = value.marca + "-" + value.nomMarca;
      ArrayGranjesAutocomplete.push(item);
    }
  });

  return (
   <span>   
      <CSSTransition
        in={props.stepActive === props.index}
        timeout={300}
        classNames={props.stepPrev<props.stepActive?'steptransitionright':'steptransitionleft'}
        unmountOnExit
        onExit={onExit}
      >
     <div className="steps-panel">
       <Autocomplete suggestions={ArrayGranjesAutocomplete} inputLabel="Engreix" inputName="engreix" callbackValue={handleGranjesEngreixSelected}/>
          { 
            isNauEngreixVisible && (
            <div className="field-wrapper field-wrapper-select">
              <select className={nauEngreixSelected && 'dirty'} name="nauEngreix" required id="nau" value={nauEngreixSelected} onChange={handleNauEngreixSelected}>
                <option value=""></option>
                { nausEngreix.map((nau) => (
                  <option key={nau.idNau} value={nau.idNau}>{nau.nomNau}</option>
                )) 
                }
              </select>
              <label htmlFor="nau">Naus</label>
            </div>
            )
        }
        <div className="steps-nav">
          <button onClick={handleClickDown} className={props.stepActive <= 1 ? classNames('disabled', 'steps-nav-btn', 'steps-nav-btn-prev') : classNames('steps-nav-btn', 'steps-nav-btn-prev')}>
            <span>Prev</span>
          </button>
          <button onClick={handleClickUp} className={props.stepActive >= 5 ? classNames('disabled', 'steps-nav-btn steps-nav-btn-next') : 'steps-nav-btn steps-nav-btn-next'}>
            <span>Next</span>
          </button>
        </div>
      </div>
    </CSSTransition>
    <CSSTransition
    in={showAlertStep}
    timeout={300}
    classNames={'alertright'}
    unmountOnExit >
    <Alert type="error">
      <div className="alert-message">
      {messageAlertStep}
      </div>
      <span className="alert-close" onClick={closeAlertStep}>
        <i className="icon-close"></i>
      </span>
    </Alert>
  </CSSTransition>
</span>)}
export default TransitionDestiEngreix